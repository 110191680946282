<template>
  <div class="header">
    <div class="logo-container">
      <img
        src="/assets/v-white.png" alt="Logo"
        class="logo-img"
      >
    </div>
    <h1>{{ 'publicPage.header' | t }}</h1>
    <LanguageSelector />
    <button
      class="print-btn"
      @click="$emit('print')"
    >
      <span class="icon"><i class="icon-glyphicon icon-print" /></span>
      <span>{{ 'publicPage.print' | t }}</span>
    </button>
  </div>
</template>

<script>
import { ASSETS_BASE_URL } from '~/lib/urls'
import LanguageSelector from './LanguageSelector'

export default {
  name: 'Header',
  components: {
    LanguageSelector
  },
  data () {
    return {
      assetsUrl: ASSETS_BASE_URL
    }
  }
}
</script>
<style lang="less" scoped>
@import '~/assets/styles/import.less';

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;

  h1 {
    display: inline-block;
    margin-right: 15px;
    font-size: 26px;
    vertical-align: middle;
  }

  .logo-container {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  .logo-img {
    max-height: 25px;
    height: auto;
  }

  .print-btn {
    padding: 5px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: medium;
    line-height: 20px;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom, @white, #e6e6e6);
    cursor: pointer;
    margin-left: auto;

    .icon {
      vertical-align: middle;

      .icon-print {
        margin-top: 2px;
        font-size: small;
      }
    }
  }
}

</style>
